<template>
  <div class="container">
    <div
      class="left-menu"
      :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }"
    >
      <left-list :leftIndex="'3'" :isCollapse="isCollapse"></left-list>
    </div>
    <div
      class="right-content"
      :style="{ width: !isCollapse ? '88%' : '95.5%' }"
    >
      <div class="top-nav">
        <top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
      </div>
      <div class="date-table">
        <div class="title">
          <span>添加管理员</span>
        </div>
        <div class="admin-from">
          <div class="from-item">
            <span>用户名称<span class="txt-red">*</span>：</span>
            <div class="from-item-input">
              <input type="text" v-model="fromList.name" />
            </div>
          </div>
          <!-- <div class="from-item">
            <span>所属角色<span class="txt-red">*</span>：</span>
            <div class="from-item-select">
              <el-select v-model="fromList.role" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div> -->
          <div class="from-item">
            <span>登录密码<span class="txt-red">*</span>：</span>
            <div class="from-item-input">
              <input type="password" v-model="fromList.password" />
            </div>
          </div>
          <div class="from-item">
            <span>确认密码<span class="txt-red">*</span>：</span>
            <div class="from-item-input">
              <input type="password" v-model="fromList.andpass" />
            </div>
          </div>
          <div class="from-item">
            <el-button type="primary" @click="insertUser">提交</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftList from "../../components/leftList.vue";
import topHeader from "../../components/header.vue";
export default {
  components: {
    leftList,
    topHeader,
  },
  inject: ["reload"],
  data() {
    return {
      options: [
        {
          value: "1",
          label: "角色1",
        },
        {
          value: "2",
          label: "角色2",
        },
      ],
      fromList: {
        name: "",
        role: "",
        password: "",
        andpass: "",
      },
      value: "",
      isCollapse: false,
    };
  },
  created() {},
  methods: {
    insertUser() {
      if (this.fromList.name == "") {
        this.$notify({
          title: "提示",
          message: "姓名不可为空",
          type: "warning",
        });
        return false;
      }
      if (this.fromList.password == "") {
        this.$notify({
          title: "提示",
          message: "请选择填写密码",
          type: "warning",
        });
        return false;
      }
      if (this.fromList.password != this.fromList.andpass) {
        this.$notify({
          title: "提示",
          message: "俩次密码不一致，请检查！",
          type: "warning",
        });
        return false;
      }
      this.$post("user/create", {
        username: this.fromList.name,
        // role_id:this.fromList.role,
        password: this.fromList.password,
      }).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "提示",
            message: "创建成功！",
            type: "success",
          });
          this.$router.push("/administrators");
        } else {
          this.$notify.error({
            title: "提示",
            message: res.data.message,
          });
        }
      });
    },
    menuitemClick(pushUrl) {
      //   console.log(pushUrl);
      if (this.$route.path == pushUrl) {
        this.reload();
      } else if (pushUrl) {
        this.$router.push(pushUrl);
      } else {
        this.$notify.error({
          title: "提示",
          message: "暂无页面",
        });
        this.reload();
      }
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.right-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: top;
  display: inline-block;

  &::-webkit-scrollbar {
    display: none;
  }

  .date-table {
    padding: 15px 30px;
    margin-bottom: 20px;
    .admin-from {
      margin-top: 30px;
      padding-left: 150px;
      .from-item {
        margin-top: 50px;
        .from-item-select {
          display: inline-block;
          margin-left: 30px;
        }
        .from-item-input {
          display: inline-block;
          width: 200px;
          border-bottom: 1px solid #bbb5b5;
          margin-left: 30px;
          input {
            width: 190px;
            border: 0px;
            outline: none;
          }
        }
      }
    }
  }

  .title {
    color: #000;
    height: 10px;
    padding-left: 15px;
    line-height: 10px;
    border-left: 5px solid #80c4f8;
    margin-bottom: 10px;
  }

  .top-nav {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 20px solid #f2f2f2;

    .left-icon {
      i {
        cursor: pointer;
        color: #3291f8;
        font-size: 30px;
      }

      .el-icon-refresh-right {
        margin-left: 30px;
      }
    }

    .right-user {
      font-size: 14px;

      span {
        vertical-align: middle;
        margin-right: 20px;
      }

      .colse-btn {
        cursor: pointer;
        vertical-align: middle;
        border: 1px solid #999;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 50px;

        span {
          margin-right: 10px;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.left-menu {
  display: inline-block;

  .iconyonghuzu {
    font-size: 18px;
    margin: 0 5px;
  }
}

.el-menu {
  width: 100%;
  background-color: #20222a;
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  background-color: #20222a;
  color: #fff;
  height: 100vh;
  width: 100%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #000;
  background-color: #ecf5ff;
}

.el-menu-item {
  color: #fff;

  i {
    color: #3291f8;
  }
}

.el-menu-item.is-active {
  background-color: #ecf5ff;
  color: #20222a;
}

.menu-h5 {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

.menu-icon {
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 30px 22px;
}
</style>
